<template>
  <div class="payments">
    <v-card class="mb-2">
      <v-card-title> Laporan Pembayaran </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="filter.dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Total Pembayaran </v-card-title>

      <v-card-text>
        <h2 v-text="payments.total"></h2>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="payments.headers"
        :items="payments.data"
        :loading="payments.loading"
        :options.sync="options"
        :server-items-length="payments.count"
        class="elevation-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            color="light-blue"
            small
            @click="openDetails(item.supplier_id)"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  name: 'Payments',

  components: {DateRangePicker},

  data() {
    return {
      filter: {
        dateRange: null,
      },

      payments: {
        count: 0,

        data: [],

        headers: [
          { text: 'Supplier', value: 'supp_name' },
          { text: 'Nilai', value: 'amount' },
          { text: '', sortable: false, value: 'actions' },
        ],

        loading: false,

        total: 0,
      },

      options: {},

      search: '',
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.getData()
      },
    },

    'filter.dateRange'() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.payments.data = []
      this.payments.loading = true

      this.$http
        .get('/purchasing/transactions/report/22', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.filter.dateRange ? this.date2sql(this.filter.dateRange[0]) : '',
            endDate: this.filter.dateRange ? this.date2sql(this.filter.dateRange[1]) : '',
          },
        })
        .then((result) => {
          this.payments.count = result.data.count
          this.payments.data = result.data.result
          this.payments.total = result.data.total
          this.payments.loading = false
        })
    },

    openDetails(supplierID) {
      this.$router.push(
        '/purchasing/payments/' +
          supplierID +
          '/' +
          this.date2sql(this.filter.dateRange[0]) +
          '/' +
          this.date2sql(this.filter.dateRange[1])
      )
    },
  },
}
</script>
